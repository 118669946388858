<template>
  <div class="w-full pt-5 pb-5">
    <template>
      <div class="flex items-center">
        <div class="border-r border-romanSilver h-6 flex">
          <BackButton
            label="Back"
            @onClick="$router.back()"
            variant="secondary"
            class="ml-2"
          />
        </div>
        <h1 class="text-xl text-left font-extrabold mx-4">Agreement Details</h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </template>
    <div style="height: 100%" class="mt-20" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <template v-else>
      <div class="p-5 flex flex-col shadow m-5 rounded-md gap-4">
        <template>
          <div class="flex justify-between items-center">
            <span
              class="font-bold text-lg leading-5 tracking-normal capitalize text-darkPurple"
            >
              {{ agreementData.title }}
            </span>
            <template>
              <div
                class="flex justify-between items-center gap-3 border rounded-md border-desire px-2 py-1 bg-red-100"
              >
                <Icon class-name="text-desire w-5 h-5" icon-name="icon-info" />
                <span
                  class="text-desire font-normal text-sm leading-5 capitalize"
                >
                  {{ agreementData.totalEmployees - agreementData.submitted }}
                  Employees Have Not Submitted
                </span>
                <span
                  class="text-desire font-black text-xs leading-6 uppercase hover:underline cursor-pointer"
                  @click="complianceModal = true"
                >
                  View
                </span>
              </div>
            </template>
          </div>
        </template>
        <template>
          <div
            class="border border-dashed bg-white border-romanSilver rounded-md gap-4 p-3 mt-4"
          >
            <div
              class="flex justify-between items-center w-full gap-4 pb-2 cursor-pointer"
              @click="handleShowDetails"
            >
              <span
                class="font-black text-xs leading-5 uppercase tracking-wider text-romanSilver"
              >
                Performance Details
              </span>
              <span
                class="w-9/12 border-t border-solid border-romanSilver h-0"
              />
              <Icon
                class-name="text-darkPurple mx-auto self-center"
                size="xms"
                :icon-name="showDetails ? 'chevronDown' : 'chevronRight'"
              />
            </div>
            <div class="flex flex-col gap-2" :class="{ hidden: !showDetails }">
              <div class="flex p-3 shadow rounded-md gap-2">
                <Icon
                  class-name="text-romanSilver self-start pt-1"
                  size="s"
                  icon-name="doc-cycle"
                />
                <div class="flex flex-col">
                  <span
                    class="font-black leading-5 text-xs tracking-wide text-darkPurple uppercase"
                  >
                    {{ agreementData.cycle }}
                  </span>
                  <span
                    class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                  >
                    {{ agreementData.description }}
                  </span>
                </div>
              </div>
              <div class="flex flex-wrap">
                <div
                  class="flex p-3 shadow rounded-md gap-2 w-2/5 mr-2 mt-2 flex-grow"
                >
                  <Icon
                    class-name="text-romanSilver self-start pt-1"
                    size="xs"
                    icon-name="calendar"
                  />
                  <div class="flex flex-col">
                    <span
                      class="font-black leading-5 text-xs tracking-wide text-carrotOrange uppercase"
                    >
                      Period
                    </span>
                    <span
                      class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                    >
                      {{
                        $DATEFORMAT(
                          new Date(agreementData.period.start),
                          "MMMM dd, yyyy"
                        )
                      }}
                      -
                      {{
                        $DATEFORMAT(
                          new Date(agreementData.period.end),
                          "MMMM dd, yyyy"
                        )
                      }}
                    </span>
                  </div>
                </div>
                <div
                  class="flex p-3 shadow rounded-md gap-2 w-2/5 mt-2 flex-grow"
                  v-for="(cycle, index) in agreementCycles"
                  :key="index"
                >
                  <Icon
                    class-name="text-romanSilver self-start pt-1"
                    size="xs"
                    icon-name="reviewicon"
                  />
                  <div class="flex flex-col">
                    <span
                      class="font-black leading-5 text-xs tracking-wide text-blueCrayola uppercase"
                    >
                      {{ cycle.name }}
                    </span>
                    <span
                      class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                    >
                      {{ $DATEFORMAT(new Date(cycle.start), "MMMM dd, yyyy") }}
                      -
                      {{ $DATEFORMAT(new Date(cycle.end), "MMMM dd, yyyy") }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex justify-evenly items-center gap-2 mt-2 w-full">
                <div class="flex flex-col p-3 shadow rounded-md w-full">
                  <span
                    class="font-black leading-5 text-xs tracking-wide text-romanSilver uppercase"
                  >
                    Total Employees
                  </span>
                  <span
                    class="font-semibold leading-5 text-xs tracking-wide text-darkPurple uppercase"
                  >
                    {{ agreementData.totalEmployees }}
                  </span>
                </div>
                <div class="flex flex-col p-3 shadow rounded-md w-full">
                  <span
                    class="font-black leading-5 text-xs tracking-wide text-romanSilver uppercase"
                  >
                    Number of Submitted
                  </span>
                  <span
                    class="font-semibold leading-5 text-xs tracking-wide text-mediumSeaGreen uppercase"
                  >
                    {{ agreementData.submitted }}
                  </span>
                </div>
                <div class="flex flex-col p-3 shadow rounded-md w-full">
                  <span
                    class="font-black leading-5 text-xs tracking-wide text-romanSilver uppercase"
                  >
                    Pending
                  </span>
                  <span
                    class="font-semibold leading-5 text-xs tracking-wide text-carrotOrange uppercase"
                  >
                    {{ agreementData.totalEmployees - agreementData.submitted }}
                  </span>
                </div>
                <div class="flex flex-col p-3 shadow rounded-md w-full">
                  <span
                    class="font-black leading-5 text-xs tracking-wide text-romanSilver uppercase"
                  >
                    Compliance
                  </span>
                  <span
                    class="font-semibold leading-5 text-xs tracking-wide text-desire uppercase"
                  >
                    ({{
                      (agreementData.submitted / agreementData.totalEmployees) *
                      100
                        ? Math.round(
                            (agreementData.submitted /
                              agreementData.totalEmployees) *
                              100
                          )
                        : "0"
                    }}%)
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <card-footer
          reloadcard
          showcalendar
          :show-bulk-actions="showBulk"
          :bulk-options="bulkActionOptions"
          @action="performBulkAction($event)"
          @reload="reload()"
          @sortType="handleSort($event)"
          @option="handleOptionSelect($event)"
          @searchResult="handleSearch($event)"
        />
      </div>
    </template>
    <template v-if="!loading">
      <Table
        :headers="AppHeader"
        :items="items"
        aria-label="advance Table"
        class="w-full px-5"
        :has-checkbox="true"
        :has-number="true"
        :loading="loadingTable"
        :pagination-list="metaData"
        style="width: 100%"
        id="printMe"
        @page="handlePage($event)"
        @itemsPerPage="handleItemsPerPage($event)"
        @rowSelected="displayRow($event)"
        page-sync
      >
        <template v-slot:item="{ item }">
          <div v-if="item.photo">
            <div>
              <img
                class="mr-3"
                :src="item.data.photo"
                v-if="item.data.photo"
                alt="user photo"
                style="height: 30px; width: 30px; border-radius: 5px"
              />
              <div
                style="height: 30px; width: 30px; border-radius: 5px"
                class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
                v-else
              >
                {{ $getInitials(`${item.data.name}`) }}
              </div>
            </div>
          </div>
          <div
            v-if="item.name"
            class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5"
          >
            <div class="flex">
              <span class="text-darkPurple capitalize mr-3">
                {{ item.data.name }}
              </span>
              <div
                class="text-flame cursor-pointer"
                v-if="item.data.agreementId"
                @click="getComments(item.data.agreementId, item.data.id)"
              >
                <Icon
                  icon-name="icon-message-outline"
                  size="xms"
                  class-name="w-5 h-5 ml-1"
                />
              </div>
            </div>
          </div>
          <div
            v-if="item.manager"
            class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5"
          >
            <span class="text-darkPurple capitalize">
              {{ item.data.manager }}
            </span>
          </div>
          <div
            v-if="item.location"
            class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5"
          >
            <span class="text-darkPurple capitalize">
              {{ item.data.location }}
            </span>
          </div>
          <div
            v-if="item.position"
            class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5"
          >
            <span class="text-darkPurple capitalize">
              {{ item.data.position }}
            </span>
          </div>
          <div
            v-if="item.designation"
            class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5"
          >
            <span class="text-darkPurple capitalize">
              {{ item.data.designation }}
            </span>
          </div>
          <div v-if="item.status">
            <div class="flex">
              <div
                class="flex flex-grow"
                v-if="item.data.status === 'submitted'"
              >
                <Badge
                  :label="item.data.status"
                  variant="primary"
                  :background-color="`rgba(19, 181, 106, 0.15)`"
                  :color="'#13B56A'"
                  class="capitalize"
                  style="
                    margin-right: 12px;
                    font-size: 14px;
                    padding: 12px 10px;
                    border-radius: 5px;
                  "
                />
              </div>

              <div
                class="flex flex-grow"
                v-if="
                  item.data.status === 'shared' ||
                  item.data.status === 'pending'
                "
              >
                <Badge
                  label="Pending"
                  variant="primary"
                  :background-color="`rgba(233, 147, 35, 0.15)`"
                  :color="'rgba(233, 147, 35, 1)'"
                  class="capitalize"
                  style="
                    margin-right: 12px;
                    font-size: 14px;
                    padding: 12px 10px;
                    border-radius: 5px;
                  "
                />
              </div>

              <div
                class="flex flex-grow"
                v-if="item.data.status === 'not submitted'"
              >
                <Badge
                  label="not submitted"
                  variant="primary"
                  :background-color="`rgba(234, 60, 83, 0.15)`"
                  :color="'#EA3C53'"
                  class="capitalize"
                  style="
                    margin-right: 12px;
                    font-size: 14px;
                    padding: 12px 10px;
                    border-radius: 5px;
                  "
                />
              </div>
            </div>
          </div>
          <div v-if="item.dateSubmitted">
            <span class="mr-4 text-center" v-if="item.data.dateSubmitted">
              {{
                $DATEFORMAT(new Date(item.data.dateSubmitted), "MMMM dd, yyyy")
              }}
            </span>
            <span v-else class="mr-4 text-center">---</span>
          </div>
          <span v-if="item.id">
            <Menu @click.stop="" class="w-10 h-10" left top="-20" margin="45">
              <template v-slot:title>
                <Icon
                  icon-name="more_icon"
                  class-name="text-darkPurple mr-2"
                  size="xs"
                />
              </template>
              <template>
                <div class="flex flex-col w-48 justify-start items-start">
                  <div
                    class="flex justify-between items-center cursor-pointer p-2"
                    v-if="item.data.status === 'not submitted'"
                    @click="setReminder([item.data.id])"
                  >
                    <Icon
                      icon-name="bell"
                      class-name="text-blueCrayola mr-2 w-5 h-5"
                    />
                    <span class="text-darkPurple text-sm leading-5 font-normal"
                      >Send Reminder</span
                    >
                  </div>
                  <div
                    class="flex justify-between items-center p-2 cursor-pointer"
                    v-if="item.data.status !== 'not submitted'"
                    @click="
                      $router.push({
                        name: 'EssPerformanceEmployeeDetails',
                        params: { id: item.data.id, year: item.data.year },
                      })
                    "
                  >
                    <Icon
                      icon-name="icon-eye"
                      class-name="text-blueCrayola mr-2 w-5 h-5"
                    />
                    <span class="text-darkPurple text-sm leading-5 font-normal"
                      >View Agreement</span
                    >
                  </div>
                  <div
                    class="flex justify-between items-center cursor-pointer p-2"
                    v-if="
                      item.data.status === 'submitted' && !item.data.approved
                    "
                    @click="returnAgreement(item.data.agreementId)"
                  >
                    <Icon
                      icon-name="undo"
                      size="xms"
                      class-name="mr-2 w-5 h-5"
                    />
                    <span
                      class="text-darkPurple text-sm leading-5 font-normal ml-1"
                      >Return to Employee</span
                    >
                  </div>
                  <div
                    class="flex justify-between items-center cursor-pointer p-2"
                    v-if="
                      item.data.status === 'submitted' && !item.data.approved
                    "
                    @click="approveAgreement(item.data.agreementId)"
                  >
                    <Icon
                      icon-name="icon-check"
                      size="xms"
                      class-name="mr-2 w-5 h-5"
                    />
                    <span
                      class="text-darkPurple text-sm leading-5 font-normal ml-1"
                      >Approve</span
                    >
                  </div>
                  <div
                    class="flex justify-between items-center cursor-pointer p-2 -ml-1"
                    v-if="
                      item.data.status === 'submitted' && item.data.approved
                    "
                    @click="
                      $router.push({
                        name: 'EssPerformanceEmployeeAppraisals',
                        params: {
                          year: item.data.year,
                          userId: item.data.id,
                          cycle: agreementCycles[0].name,
                          goalId: agreementData.id,
                        },
                      })
                    "
                  >
                    <Icon
                      icon-name="doc-cycle"
                      size="s"
                      class-name="text-mediumSeaGreen mr-2 w-5 h-5"
                    />
                    <span class="text-darkPurple text-sm leading-5 font-normal"
                      >Go to Appraisal</span
                    >
                  </div>

                  <div
                    class="hover:bg-ghostWhite w-full rounded-md cursor-pointer"
                    v-if="item.data.status !== 'not submitted'"
                    @click="
                      $router.push({
                        name: 'EssEmployeeStatusReports',
                        params: {
                          id: item.data.id,
                          year: $route.params.year,
                        },
                      })
                    "
                  >
                    <div class="flex justify-start items-center p-2 gap-2">
                      <Icon
                        icon-name="file-document"
                        class-name="text-carrotOrange"
                        size="xs"
                      />
                      <span
                        class="text-darkPurple text-sm leading-5 font-normal"
                      >
                        Performance Reports
                      </span>
                    </div>
                  </div>

                  <div
                    class="flex justify-between items-center cursor-pointer p-2"
                    v-if="item.data.status !== 'not submitted'"
                    @click="getComments(item.data.agreementId, item.data.id)"
                  >
                    <Icon
                      icon-name="comment-outline"
                      size="xms"
                      class-name="text-mediumSeaGreen mr-3 w-5 h-5 ml-1"
                    />
                    <span class="text-darkPurple text-sm leading-5 font-normal"
                      >Comment</span
                    >
                  </div>
                </div>
              </template>
            </Menu>
          </span>
        </template>
      </Table>
    </template>

    <RightSideBar
      v-if="complianceModal"
      @submit="setReminder(alertIds)"
      @close="complianceModal = false"
      submit-button-width="12rem"
      submit="Send Reminder to All"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <span class="text-darkPurple font-bold text-lg leading-5 p-0">
          Compliance
        </span>
      </template>
      <template v-slot:subtitle>
        <div class="pb-5 flex flex-col">
          <span
            class="text-darkPurple font-semibold text-base pb-4 leading-5 p-0"
            >Employee Who Have not Submitted</span
          >
          <div class="text-darkPurple flex justify-start items-center gap-5">
            <p class="font-normal font-base leading-5 text-jet">
              These employees have not submitted their agreements. You can send
              them a reminder (click <span class="text-desire">here</span> for
              reminder settings) or a direct message
            </p>
          </div>
        </div>
      </template>
      <template>
        <div class="">
          <span class="font-normal text-xs leading-5 text-jet">
            {{ agreementData.totalEmployees - agreementData.submitted }}
            Employees have not submitted
          </span>
          <div>
            <div
              class="flex justify-between items-center border-b my-2 border-dashed border-romanSilver"
              v-for="(item, index) in nonSubmitted"
              :key="index"
            >
              <div class="flex justify-start items-start gap-1 py-2">
                <img
                  class="mr-3"
                  :src="item.photo"
                  v-if="item.photo"
                  alt="user photo"
                  style="height: 30px; width: 30px; border-radius: 5px"
                />
                <div
                  style="height: 30px; width: 30px; border-radius: 5px"
                  class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
                  v-else
                >
                  {{ $getInitials(`${item.name}`) }}
                </div>
                <div class="flex flex-col justify-start items-start">
                  <span class="font-normal text-base text-darkPurple">{{
                    item.name
                  }}</span>
                  <span
                    class="font-semibold uppercase text-romanSilver"
                    style="font-size: 10px"
                    >{{ item.designation }}</span
                  >
                </div>
              </div>
              <div class="flex justify-between items-center gap-8">
                <span class="cursor-pointer" @click="setReminder([item.id])">
                  <Icon
                    icon-name="bell"
                    class-name="text-darkPurple"
                    size="xs"
                  />
                </span>
                <span class="cursor-pointer">
                  <Icon
                    icon-name="icon-message-outline"
                    class-name="text-carrotOrange -ml-3"
                    size="xs"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </RightSideBar>

    <RightSideBar
      v-if="commentModal"
      @submit="sendComment"
      @close="commentModal = false"
      submit="Save"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <span class="text-darkPurple font-bold text-lg leading-5 p-0"
          >Comments</span
        >
      </template>
      <div class="w-full flex">
        <div class="flex flex-col w-full" style="min-height: 70vh">
          <CommentsBox
            :comments="allComments"
            @markRead="markAsRead($event)"
            @deleteComment="deleteComments($event)"
            :loading="loadComments"
          />
          <div class="-mb-12" style="margin-top: auto">
            <CTextarea
              placeholder="--Message--"
              variant="primary"
              class="mt-2"
              value=""
              :row="8"
              :height="90"
              :col="30"
              :input="null"
              v-model="comment"
            />
          </div>
        </div>
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import CardFooter from "@/components/CardFooter";
import Menu from "@/components/Menu";
import RightSideBar from "@/components/RightSideBar";
import CommentsBox from "@/components/commentsBox";
import paramsMixin from "@/utilities/paramsMixin";
import timeFormatMixin from "@/utilities/timeFormatMixin";

export default {
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    BackButton,
    Breadcrumb,
    CardFooter,
    CommentsBox,
    Table,
    CTextarea,
    Badge,
    Menu,
    RightSideBar,
  },
  data() {
    return {
      commentModal: false,
      complianceModal: false,
      loading: true,
      calendarFilter: false,
      showBulk: false,
      commentAgreementId: "",
      comment: "",
      receiverId: "",
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Agreements",
          href: "agreements",
          id: "PerformanceAgreements",
        },
        {
          disabled: false,
          text: "Agreement Details",
          href: "agreement-details",
          id: "AgreementDetails",
        },
      ],
      agreementData: {
        title: null,
        cycle: null,
        description: null,
        period: { start: null, end: null },
        totalEmployees: null,
        submitted: null,
        compliance: null,
        id: "",
      },
      agreementCycles: [],
      appraisalCycleId: "",
      showDetails: true,
      loadingTable: true,
      reminderMsg: "",
      AppHeader: [
        { title: "", value: "photo", width: "6" },
        { title: "Name", value: "name" },
        { title: "Designation", value: "designation", filter: true },
        { title: "Function", value: "position", filter: true },
        { title: "Manager", value: "manager", filter: true },
        { title: "Location", value: "location", filter: true },
        { title: "Status", value: "status" },
        { title: "Date Submitted", value: "dateSubmitted" },
        { title: "", value: "id", image: true },
      ],
      items: [],
      nonSubmitted: [],
      paginationList: {
        page: 1,
        lastPage: 1,
        total: 1,
        from: 1,
        to: 1,
      },
      metaData: {},
      itemsPerPage: null,
      numberOfPage: null,
      alertIds: [],
      templatesArr: [],
      allComments: [],
      loadComments: true,
      selectedEmployees: [],
      bulkActionOptions: [
        {
          icon: "icon-check",
          text: "Approve",
          action: "approve Agreement",
        },
        {
          icon: "undo",
          text: "Return to Employee",
          action: "return Agreement",
        },
      ],
      searchQuery: "",
    };
  },
  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },

    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      this.loadingTable = true;
      const search = this.searchQuery ? `${this.searchQuery}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getAgreementUsers(`${pageNumber}${itemPage}${search}`);
    },

    handleSearch(value) {
      this.searchQuery = `&search=${value}`;
      this.loadingTable = true;
      this.getAgreementUsers(this.searchQuery);
    },

    reload() {
      this.getAgreementUsers("");
    },

    displayRow(value) {
      if (value.length > 0) {
        this.selectedEmployees = value;
        this.selectedEmployees.map((employee) => {
          if (employee.status === "submitted" && !employee.approved) {
            this.showBulk = true;
          }
          return {};
        });
      } else {
        this.showBulk = false;
      }
    },

    performBulkAction(methodFunc) {
      if (methodFunc === "return Agreement") {
        this.returnAgreement("");
      }

      if (methodFunc === "approve Agreement") {
        this.approveAgreement("");
      }
    },

    getComments(agreementId, userId) {
      this.commentModal = true;
      this.allComments = [];
      this.receiverId = userId;
      this.commentAgreementId = agreementId;

      this.loadComments = true;

      this.$_getAgreementComments(agreementId)
        .then((response) => {
          const { comments } = response.data;

          const unread = [];

          this.loadComments = false;

          comments.forEach((comment) => {
            this.allComments.push({
              message: comment.comment,
              time: this.formatCommentTime(comment.date),
              date: this.formatCommentDate(comment.date),
              commentId: comment.id,
              isRead: comment.readStatus,
              receiver: comment.receiver,
              sender: comment.sender,
            });

            if (comment.readStatus === false) {
              unread.push(comment.readStatus);
            }
          });

          this.unreadComments = unread.length;
        })
        .catch((error) => {
          if (error.response.data.message !== "") {
            this.loadComments = false;
          }
        });
    },

    deleteComments(id) {
      this.$_deleteAgreementComments(id)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getComments(this.$route.params.id);
        })
        .then((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    sendComment() {
      const payload = {
        performanceAgreementId: this.commentAgreementId,
        senderId: this.$AuthUser.id,
        receiverId: this.receiverId,
        comment: this.comment,
        sendTo: "admin",
      };

      this.$_postAgreementComments(payload).then((response) => {
        this.$toasted.success(response.data.message, { duration: 5000 });
        this.getComments(this.commentAgreementId);
        this.comment = "";
      });
    },

    formatCommentTime(date) {
      const newDate = new Date(date);
      const hours = newDate.getUTCHours();
      const minutes = newDate.getMinutes();

      return `${1 + ((hours - 1) % 12)}:${minutes
        .toString()
        .padStart(2, "0")} ${hours > 11 ? "PM" : "AM"}`;
    },

    formatCommentDate(date) {
      const newDate = new Date(date);
      return newDate.toDateString();
    },

    returnAgreement(agreementId) {
      const agreementIdsArr = [];

      if (agreementId) {
        agreementIdsArr.push(agreementId);

        const payload = {
          agreementIds: agreementIdsArr,
          approvedByAdmin: false,
          approveAndSubmit: false,
        };

        this.$_returnEmployeeAgreement(payload)
          .then((response) => {
            this.$toasted.success(
              `${response.data.message} returned successfully`,
              { duration: 5000 }
            );
            this.items = [];
            this.getAgreementUsers("");
            this.getAgreements();
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      } else {
        this.selectedEmployees.map((employees) => {
          if (employees.status === "submitted") {
            agreementIdsArr.push(employees.agreementId);
          }
          return {};
        });

        const payload = {
          agreementIds: agreementIdsArr,
          approvedByAdmin: false,
          approveAndSubmit: false,
        };

        this.$_returnEmployeeAgreement(payload)
          .then((response) => {
            this.$toasted.success(
              `${response.data.message} returned successfully`,
              { duration: 5000 }
            );
            this.items = [];
            this.getAgreementUsers("");
            this.getAgreements();
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      }
    },

    approveAgreement(agreementId) {
      const agreementIdsArr = [];

      if (agreementId) {
        agreementIdsArr.push(agreementId);

        const payload = {
          agreementIds: agreementIdsArr,
          approvedByAdmin: true,
          approveAndSubmit: true,
        };

        this.$_adminApproveAgreement(payload)
          .then((response) => {
            this.$toasted.success(`${response.data.message}`, {
              duration: 5000,
            });
            this.items = [];
            this.nonSubmitted = [];
            this.alertIds = [];
            this.getAgreementUsers("");
            this.getAgreements();
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      } else {
        this.selectedEmployees.map((employees) => {
          if (employees.status === "submitted") {
            agreementIdsArr.push(employees.agreementId);
          }
          return {};
        });

        const payload = {
          agreementIds: agreementIdsArr,
          approvedByAdmin: true,
          approveAndSubmit: true,
        };

        this.$_adminApproveAgreement(payload)
          .then((response) => {
            this.$toasted.success(`${response.data.message}`, {
              duration: 5000,
            });
            this.items = [];
            this.nonSubmitted = [];
            this.alertIds = [];
            this.getAgreementUsers("");
            this.getAgreements();
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      }
    },

    activateTab(tab) {
      this.activeTab = tab;
      this.$router
        .push({ name: "Mid-Year Review", query: { activeTab: tab } })
        .catch(() => {});
    },
    handleCommentModal() {
      this.commentModal = !this.commentModal;
    },

    handleCalendarFilter() {
      this.calendarFilter = true;
    },

    setReminder(id) {
      const payload = {
        message: `${
          this.reminderMsg === ""
            ? "Reminder to submit your performance agreement"
            : this.reminderMsg
        }`,
        userIds: id,
        appraisalId: this.appraisalCycleId
      };

      this.$_agreementReminder(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
        });
    },

    handleShowDetails() {
      this.showDetails = !this.showDetails;
    },

    getAgreements() {
      this.$_getOrgPerformanceAgreement().then((response) => {
        const result = response.data.data;
        const currentYear = this.$route.params.year;

        result.forEach((info) => {
          if (info.agreementDetail.year === Number(currentYear)) {
            this.agreementData.id = info.agreementDetail.id;
            this.loading = false;
          }
        });
      });
    },

    getTemplate() {
      const currentYear = this.$route.params.year;

      this.$_getPerfomanceTemplate("").then((response) => {
        const templatesArr = response.data.PerformanceTemplate;
        this.loading = false;

        templatesArr.filter((template) => {
          if (template.year === Number(currentYear)) {
            this.appraisalCycleId = template.appraisalCycleId;
            this.agreementData.title = template.title;
            this.agreementData.cycle = template.title;
            this.agreementData.description = template.agreement_description;
            this.agreementData.period.start = this.setDates(
              template.appraisal_cycle.agreement_start
            ).toISOString();
            this.agreementData.period.end = this.setDates(
              template.appraisal_cycle.agreement_end
            ).toISOString();
          }
          return {};
        });
      });
    },

    getAppraisalCycle() {
      this.$_getAppraisalCycle(this.agreementQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;
        if (appraisal[0].cycles.length > 0) {
          appraisal[0].cycles.map((cycle) => {
            this.agreementCycles.push({
              name: cycle.name,
              start: this.setDates(cycle.appraisal_starts).toISOString(),
              end: this.setDates(cycle.appraisal_ends).toISOString(),
            });
            return {};
          });
        }
        this.getAgreements();
      });
    },

    getAgreementUsers(query) {
      const currentYear = this.$route.params.year;

      this.loadingTable = true;

      this.items = [];

      this.$_getDirectReportsAgreementsList(
        this.$AuthUser.id,
        currentYear,
        query
      ).then((response) => {
        const employees = response.data.directReports;
        this.metaData = response.data.meta;
        employees.forEach((employee) => {
          this.items.push({
            name: `${employee.fname} ${employee.lname}`,
            photo: employee.photo,
            location: employee.location,
            position: employee.function,
            designation: employee.designation,
            manager: employee.lineManager
              ? `${employee.lineManager.fname} ${employee.lineManager.lname}`
              : "---",
            status: employee.agreement_status
              ? employee.agreement_status
              : "not submitted",
            agreementId: employee.agreementId,
            dateSubmitted: employee.dateSubmitted,
            approved: employee.approved,
            id: employee.userId,
            year: currentYear,
          });
        });

        employees.filter((employee) => {
          if (employee.agreement_status !== "submitted") {
            this.nonSubmitted.push({
              name: `${employee.fname} ${employee.lname}`,
              photo: employee.photo,
              designation: employee.designation,
              id: employee.userId,
            });

            this.alertIds.push(employee.userId);
          }
          return {};
        });

        this.agreementData.totalEmployees = employees.length;
        this.agreementData.submitted = employees.length - this.alertIds.length;

        this.loadingTable = false;
      });
    },

    getPerformanceSettings() {
      this.$_getPerformanceGeneralSetting().then((response) => {
        const reminder = response.data.PerformanceSettings;
        this.reminderMsg = reminder[0].reminderMessage;
      });
    },
  },

  mounted() {
    this.getAppraisalCycle();
    this.getAgreementUsers("");
    this.getTemplate();
    this.getPerformanceSettings();
  },
};
</script>

<style scoped>
.submitted {
  background: rgba(19, 181, 106, 0.15);
  color: #27ae60;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}
.not_submitted {
  background: rgba(234, 60, 83, 0.15);
  color: #ea3c53;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}
</style>

<style>
.icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}
</style>
